import { ArrowLeft, Box, Button, CommonInfoRefill } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

import '../style.less'

interface IComponentProps {
    changeBodyToBuying: (e: React.SyntheticEvent) => void
}

export const AccountRefillInfo: FC<IComponentProps> = ({ changeBodyToBuying }) => {
    const account = useSelector(moneySelector.selectAccount)
    return (
        <div>
            <Box padding={[0, 0, 16, 0]}>
                <Button
                    className="trade-additional-modal__text-button"
                    type="text"
                    icon={<ArrowLeft />}
                    onClick={changeBodyToBuying}
                    width="214px"
                >
                    Как пополнить счёт
                </Button>
            </Box>

            <Box padding={[0, 0, 24, 0]}>
                Пополните счёт банковским переводом по указанным реквизитам
            </Box>
            {account && <CommonInfoRefill account={account} />}
        </div>
    )
}

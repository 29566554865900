import { ArrowLeft, Box, Button, QualifiedDetails } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { profileSelector } from '@store/profiles/details/selectors'

import '../style.less'

interface ITradeBuyingModalInfoBody {
    changeBodyToBuying: (e: React.SyntheticEvent) => void
}

export const LimitInfoBody: FC<ITradeBuyingModalInfoBody> = ({ changeBodyToBuying }) => {
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)
    return (
        <div className="trade-additional-modal__content">
            <Box padding={[0, 0, 16, 0]}>
                <Button
                    className="trade-additional-modal__text-button"
                    type="text"
                    icon={<ArrowLeft />}
                    onClick={changeBodyToBuying}
                >
                    Ограничения по сумме сделок
                </Button>
            </Box>
            <QualifiedDetails qualifyInfo={qualifyInfo || undefined} />
        </div>
    )
}

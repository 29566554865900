import { ArrowLeft, Box, Button, Divider, Requisites } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { profileSelector } from '@store/profiles/details/selectors'

import '../style.less'

interface ITradeBuyingModalInfoBody {
    changeBodyToBuying: (e: React.SyntheticEvent) => void
}

export const BankAccountInfo: FC<ITradeBuyingModalInfoBody> = ({ changeBodyToBuying }) => {
    const bankDetails = useSelector(profileSelector.selectBankAccountDetails)
    const requisitesPartOne = [
        {
            label: 'БИК банка',
            value: bankDetails?.bic,
        },
        {
            label: 'Номер счета (расчетный счет)',
            value: bankDetails?.account_number,
        },
    ]
    const requisitesPartTwo = [
        {
            label: 'Наименование банка',
            value: bankDetails?.bank,
        },
        {
            label: 'ИНН банка',
            value: bankDetails?.bank_inn,
        },
        {
            label: 'КПП банка',
            value: bankDetails?.bank_kpp,
        },
        {
            label: 'Корреспондентский счёт',
            value: bankDetails?.corr_account_number,
        },
    ]
    return (
        <div>
            <Box padding={[0, 0, 16, 0]}>
                <Button
                    className="trade-additional-modal__text-button"
                    type="text"
                    icon={<ArrowLeft />}
                    onClick={changeBodyToBuying}
                >
                    Банковские реквизиты
                </Button>
            </Box>
            <Box padding={[0, 0, 24, 0]}>
                Денежные средства будут зачислены на банковский счет по следующим реквизитам
            </Box>
            <Box margin={[0, 24]}>
                <Requisites requisites={requisitesPartOne} copyEnabled />
                <Divider margin={[16, 0]} />
                <Requisites requisites={requisitesPartTwo} copyEnabled />
                <Divider margin={[16, 0, 24, 0]} />
            </Box>
            <p className="trade-additional-modal__footer-text">
                Зачисление денежных средств на счёт в течение 1 рабочего дня. <br />
                Комиссия не взимается.
            </p>
        </div>
    )
}

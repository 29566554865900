import { CollectType } from '@dltru/dfa-models'
import { Button, Divider, MedalIcon, Price, RightMini, Space, WrappedSelect } from '@dltru/dfa-ui'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import './style.less'

const collectDefaultType = CollectType.account
interface IComponentProps {
    actionAccount?: (e: React.SyntheticEvent) => void
    actionLimit?: (e: React.SyntheticEvent) => void
    actionBankDetails?: (e: React.SyntheticEvent) => void
    paymentCollectType?: CollectType
    onChangeCollectType?: (value?: CollectType) => void
}
export const AccountLimitBlock: FC<IComponentProps> = ({
    actionAccount,
    actionLimit,
    actionBankDetails,
    paymentCollectType = collectDefaultType,
    onChangeCollectType,
}) => {
    const accountInfo = useSelector(moneySelector.selectAccount)
    const currentQuota = useSelector(profileSelector.selectLimitRublies)
    const bankDetails = useSelector(profileSelector.selectBankAccountDetails)
    const { getFieldValue } = useContext(FieldContext)
    const [currentCollectType, setCurrentCollectType] = useState(
        getFieldValue('payment_collect_type') ?? collectDefaultType,
    )
    useEffect(() => {
        onChangeCollectType?.(currentCollectType)
    }, [currentCollectType])
    const isUnqualifyLimit = useSelector(profileSelector.selectIsUnqualifyLimit)

    const currentAccountMoney = accountInfo?.balance?.available
        ? accountInfo.balance.available / 100
        : 0

    const collectTypeOptions = [
        {
            value: CollectType.account,
            label: `Лицевой счёт ${accountInfo?.number}`,
        },
        {
            value: CollectType.bank_account,
            label: 'Банковский счёт',
        },
    ]

    return (
        <div className="account-limit__wrapper">
            {actionBankDetails && actionAccount ? (
                <>
                    <div className="account-limit__flex-row">
                        <WrappedSelect
                            label="Зачислить средства на"
                            size="small"
                            name="payment_collect_type"
                            options={collectTypeOptions}
                            initialValue={collectDefaultType}
                            onChange={setCurrentCollectType}
                            column
                            className="account-limit__select-small"
                        />
                        <Space
                            direction="vertical"
                            size={0}
                            align="end"
                            className="account-limit__right-block"
                        >
                            {currentCollectType === CollectType.account ? (
                                <>
                                    <Button
                                        type="link"
                                        onClick={actionAccount}
                                        className="account-limit__small-button"
                                    >
                                        Пополнить <RightMini />
                                    </Button>
                                    <Price price={currentAccountMoney} />
                                </>
                            ) : (
                                <>
                                    <span>&nbsp;</span>
                                    <Button
                                        type="link"
                                        onClick={actionBankDetails}
                                        className="account-limit__small-button"
                                    >
                                        Реквизиты <RightMini />
                                    </Button>
                                </>
                            )}
                        </Space>
                    </div>
                </>
            ) : (
                <div className="account-limit__flex-row">
                    <Space direction="vertical" size={0}>
                        <span>Счёт</span>
                        <strong className="account-limit__strong-text">
                            {paymentCollectType === CollectType.account
                                ? accountInfo?.number
                                : 'Банковский счёт'}
                        </strong>
                    </Space>
                    <Space direction="vertical" size={0} align="end">
                        {actionAccount ? (
                            <Button
                                type="link"
                                onClick={actionAccount}
                                className="account-limit__small-button"
                            >
                                Пополнить <RightMini />
                            </Button>
                        ) : (
                            <span>&nbsp;</span>
                        )}
                        {paymentCollectType === CollectType.account ? (
                            <Price price={currentAccountMoney} />
                        ) : (
                            <span className="account-limit__strong-text account-limit__strong-text--black">
                                {bankDetails?.bank}
                            </span>
                        )}
                    </Space>
                </div>
            )}

            {actionLimit && currentQuota !== undefined && isUnqualifyLimit && (
                <>
                    <Divider margin={[8, 0]} />
                    <div className="account-limit__flex-row">
                        <Space align="start">
                            <MedalIcon className="account-limit__big-icon" />
                            <span>
                                Остаток годового лимита
                                <br />
                                неквалифицированного инвестора
                            </span>
                        </Space>
                        <Space direction="vertical" size={0} align="end">
                            <Price price={currentQuota} />
                            <Button
                                type="link"
                                onClick={actionLimit}
                                className="account-limit__small-button"
                            >
                                Подробнее
                            </Button>
                        </Space>
                    </div>
                </>
            )}
        </div>
    )
}
